<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { revenueChart } from "./data-profile";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
/**
 * Contacts-Profile component
 */
export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    // PageHeader,
  },
  data() {
    return {
      username: "",
      users: [],
      role: "",
      showModal: false,
      revenueChart: revenueChart,
      title: "Profile",
      items: [
        {
          text: "Utilisateurs",
          href: "/contacts/list/",
        },
        {
          text: "Détails",
          active: true,
        },
      ],
      statData: [
        {
          icon: "bx bx-check-circle",
          title: "Completed Projects",
          value: "125",
        },
        {
          icon: "bx bx-hourglass",
          title: "Pending Projects",
          value: "12",
        },
        {
          icon: "bx bx-package",
          title: "Total Revenue",
          value: "$36,524",
        },
      ],
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    console.log(this.id);

    const result = await Api.get(
      "/authentication/api/auth/user/" + this.$route.params.id
    );
    this.users = result.data.content;
    console.log(this.users);
    this.username = this.users.username;
  },
  methods: {
    confirm(role) {
      Swal.fire({
        title: "Etes vous sùre?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.removeRole(role);
        }
      });
    },

    removeRole(role) {
      this.process = true;
      console.log("role " + role + " username " + this.username)
      Api.delete(
        `/authentication/api/auth/roles/removerole?role=${role}&username=${this.username}`
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Success!", "Role Supprimé", "success");
          console.log(response);
          // location.reload()
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    modal() {
      this.showModal = true;
    },

    setRole() {
      let token = localStorage.getItem("jwtToken");
      console.log(token);

      Api.put(
        "/authentication/api/auth/roles/update?role=" +
          this.role +
          "&username=" +
          this.username
      )
        .then((response) => {
          console.log(response);
          location.reload();
          this.showModal = false;
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->

    <div class="c2play-primary" style="min-height: 200px; padding: 7%">
      <h2 class="c2play-primary">Profile</h2>
    </div>
    <div
      class="card"
      style="
        width: 86%;
        margin-left: 7%;
        position: relative;
        bottom: 70px;
        padding: 80px;
      "
    >
      <div class="row">
        <div class="col-lg-3" style="text-align: center">
          <div class="">
            <div
              class="avatar-md profile-user-wid mb-3"
              style="position: relative; left: 10%"
            >
              <span
                v-if="!users.image"
                class="c2play-bg-secondary avatar-title rounded-circle bg-soft text-primary font-size-24"
              >
                <img
                  src="@/assets/logos/images/profile.png"
                  alt
                  class="img-thumbnail rounded-circle c2play-primary"
                />
              </span>
              <!-- <div v-if="!users.image" class="avatar-xs">
                        <span class="avatar-title rounded-circle c2play-primary">{{users.surname.charAt(0).toUpperCase()}}{{user.name.charAt(0).toUpperCase()}}</span>
                      </div>         -->
              <img
                v-else
                :src="users.image.url"
                alt
                class="c2play-primary"
                style="
                  height: 80px;
                  width: 80px;
                  border-radius: 50%;
                  position: relative;
                  bottom: 20px;
                  background-color: #fff;
                "
              />
              <!-- <div v-if="!users.image" style="height: 50px;width: 50px; font-size: 1.4em;">
                        <span class="avatar-title rounded-circle c2play-primary">{{users.surname.charAt(0).toUpperCase()}}{{users.name.charAt(0).toUpperCase()}}</span>
                    </div> -->
            </div>
          </div>

          <div
            class="pt-4"
            style="position: relative; right: 20%; font-size: 1.2em"
          >
            <div>
              <h3 class="text-truncate">{{ users.username }}</h3>
            </div>
            <br />
            <div class="row">
              <div class="col-6">
                <h5 class="font-size-15">125</h5>
                <p class="text-muted mb-0">Projects</p>
              </div>
              <div class="col-6">
                <h5 class="font-size-15">$1245</h5>
                <p class="text-muted mb-0">Revenue</p>
              </div>
            </div>
          </div>
          <br />

          <div
            class=".c2play-border-primary"
            style="
              height: 130%;
              width: 0;
              position: relative;
              left: 100%;
              bottom: 72%;
              border: 1px solid #eef8ea;
            "
          ></div>
        </div>
        <div class="col-lg-9" style="font-size: 1.2em">
          <h4 class="card-title mb-4" style="font-size: 1.3em">
            {{ $t("pages.userProfile.entete.title") }}
          </h4>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">
                      {{ $t("pages.userProfile.entete.th1") }} :
                    </th>
                    <td>{{ users.name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {{ $t("pages.userProfile.entete.th2") }} :
                    </th>
                    <td>{{ users.surname }}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {{ $t("pages.userProfile.entete.th3") }} :
                    </th>
                    <td>{{ users.username }}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {{ $t("pages.userProfile.entete.th4") }} :
                    </th>
                    <td>{{ users.telephone }}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {{ $t("pages.userProfile.entete.th5") }} :
                    </th>
                    <td>{{ users.email }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Roles:</th>
                    <td>
                      <div
                        class="row"
                        style="position: relative; right: 20%; font-size: 1.2em"
                      >
                        <div class="col-lg-11">
                          <p class="text-muted mb-0 text-truncate">
                            <span v-for="rol in users.roles" :key="rol.id">
                              <span
                                v-if="rol.name == 'ROLE_ADMIN'"
                                class="bg-success badge"
                                ><span
                                  @click="confirm(rol.name)"
                                  style="
                                    margin-right: 10px;
                                    font-size: 0.9em;
                                    cursor: pointer;
                                  "
                                  >X</span
                                >{{ $t("roles.admin") }}</span
                              >
                              <span
                                v-if="rol.name == 'ROLE_MODERATOR'"
                                class="bg-warning badge"
                                ><span
                                  @click="confirm(rol.name)"
                                  style="
                                    margin-right: 10px;
                                    font-size: 0.9em;
                                    cursor: pointer;
                                  "
                                  >X</span
                                >{{ $t("roles.mod") }}</span
                              >
                              <span
                                v-if="rol.name == 'ROLE_CREATOR'"
                                class="bg-danger badge"
                                ><span
                                  @click="confirm(rol.name)"
                                  style="
                                    margin-right: 10px;
                                    font-size: 0.9em;
                                    cursor: pointer;
                                  "
                                  >X</span
                                >{{ $t("roles.create") }}</span
                              >
                              <span
                                v-if="rol.name == 'ROLE_AWARD'"
                                class="bg-danger badge"
                                ><span
                                  @click="confirm(rol.name)"
                                  style="
                                    margin-right: 10px;
                                    font-size: 0.9em;
                                    cursor: pointer;
                                  "
                                  >X</span
                                >Award</span
                              >
                              <span
                                v-if="rol.name == 'ROLE_USER'"
                                class="bg-primary badge"
                                ><span
                                  @click="confirm(rol.name)"
                                  style="
                                    margin-right: 10px;
                                    font-size: 0.9em;
                                    cursor: pointer;
                                  "
                                  >X</span
                                >{{ $t("roles.user") }}</span
                              >
                              <span
                                v-if="rol.name == 'ROLE_EDITOR'"
                                class="bg-info badge"
                                ><span
                                  @click="confirm(rol.name)"
                                  style="
                                    margin-right: 10px;
                                    font-size: 0.9em;
                                    cursor: pointer;
                                  "
                                  >X</span
                                >{{ $t("roles.edit") }}</span
                              >
                            </span>
                          </p>
                        </div>
                        <div
                          class="col-lg-1"
                          style="
                            position: relative;
                            top: 300%;
                            font-size: 1.2em;
                          "
                        >
                          <i
                            class="mdi mdi-account-edit-outline update-user"
                            @click="modal"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="font-size: 1.3em">
      <b-modal v-model="showModal" hide-footer centered header-class="border-0">
        <div class="text-center mb-4">
          <div class="row justify-content-center">
            <div class="col-xl-10">
              <div class="mb-3 row">
                <label class="col-form-label">{{
                  $t("pages.userProfile.formulaire.selectRole")
                }}</label>
                <div class="col-md-12">
                  <select class="form-control" v-model="role">
                    <option value="admin">{{ $t("roles.admin") }}</option>
                    <option value="award">Administrateur de compétition</option>
                    <option value="mod">{{ $t("roles.mod") }}</option>
                    <option value="createur">
                      {{ $t("roles.create") }} de contenu
                    </option>
                    <option value="editeur">{{ $t("roles.edit") }}</option>
                  </select>
                </div>
              </div>
              <button
                class="btn btn-primary col-md-12"
                type="button"
                id="button-addon2"
                @click="setRole"
              >
                {{ $t("addButton") }}
              </button>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- 
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">My Projects</h4>
            <div class="table-responsive">
              <table class="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Projects</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Deadline</th>
                    <th scope="col">Budget</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Skote admin UI</td>
                    <td>2 Sep, 2019</td>
                    <td>20 Oct, 2019</td>
                    <td>$506</td>
                  </tr>

                  <tr>
                    <th scope="row">2</th>
                    <td>Skote admin Logo</td>
                    <td>1 Sep, 2019</td>
                    <td>2 Sep, 2019</td>
                    <td>$94</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Redesign - Landing page</td>
                    <td>21 Sep, 2019</td>
                    <td>29 Sep, 2019</td>
                    <td>$156</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>App Landing UI</td>
                    <td>29 Sep, 2019</td>
                    <td>04 Oct, 2019</td>
                    <td>$122</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>Blog Template</td>
                    <td>05 Oct, 2019</td>
                    <td>16 Oct, 2019</td>
                    <td>$164</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>Redesign - Multipurpose Landing</td>
                    <td>17 Oct, 2019</td>
                    <td>05 Nov, 2019</td>
                    <td>$192</td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td>Logo Branding</td>
                    <td>04 Nov, 2019</td>
                    <td>05 Nov, 2019</td>
                    <td>$94</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> -->
    </div>
    <!-- end row -->
  </Layout>
</template>
